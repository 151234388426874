const SearchIcon = () => (
  <svg width={20} height={20} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='m18.334 18.333-5.287-5.287M8.334 15a6.667 6.667 0 1 0 0-13.333 6.667 6.667 0 0 0 0 13.333Z'
      stroke='#4F4F4F'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default SearchIcon
