const RightArrow = () => (
  <svg width={24} height={24} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.705 6.705a.998.998 0 0 0-1.411 1.41L13.17 12l-3.876 3.885a.998.998 0 0 0 1.411 1.41l4.588-4.588a1 1 0 0 0 0-1.414l-4.588-4.588Z'
      fill='#252525'
    />
  </svg>
)

export default RightArrow
