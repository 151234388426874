const LeftArrow = () => (
  <svg width={24} height={24} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.295 17.295a.997.997 0 0 0 1.411-1.41L10.83 12l3.876-3.885a.997.997 0 0 0-1.411-1.41l-4.588 4.588a1 1 0 0 0 0 1.414l4.588 4.588Z'
      fill='#252525'
    />
  </svg>
)

export default LeftArrow
