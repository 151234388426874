/*
    Change the account name here from "broadcast" to whichever account is relevant
        for your plugin.
*/
import { PluginAPI } from 'https://jyskfynske.drpublish.aptoma.no/js/shared/PluginAPI/PluginAPI-5.0.js'

/*
    This file contains all communication with the DrPublish editor
        using the PluginAPI
*/
export class DrPublish {
  static config = {}
  static pluginName = ''

  /*
        Creates an "embedded object", the relevant DrPublish object for containing custom data.
        Then inserts the object into the article with an editor element (html placeholder).
        Then adds your data object as an "embedded asset" to the "embedded object".
            The naming and process is of historic reasons and will generally follow the same sequence.
    */
  static async insert(markup, embedCode, name) {
    const id = await PluginAPI.createEmbeddedObject()
    await PluginAPI.editorInsertElement(id, markup)
    await PluginAPI.addEmbeddedAsset(id, { code: embedCode, title: name })
  }

  /*
        PluginAPI.setPluginName() has to be the initial call to the PluginAPI in order for it to be able
            to communicate with DrPublish, and the name used must match that used when adding the plugin
            to the publication.
        The config values are defined in the config.json file which is added to the publication plugin config.
        registerMenuAction and editorInitializeMenu basically does the same, but the first adds a custom button
            and the standard buttons like "delete";
        The "on" methods adds event listeners, as does the "on-api-event" call.
    */

  static async init(pluginName) {
    this.pluginName = pluginName
    PluginAPI.setPluginName(pluginName)
    this.config = await PluginAPI.getConfiguration()
  }
}
